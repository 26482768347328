import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: []
  },
  mutations: {
    clearProjects: (state) => {
      state.projects = [];
    },
    addProjects: (state, projects) => {
      state.projects.push(...projects);
    }
  },
  getters: {
    projects: state => state.projects
  },
  actions: {},
  modules: {}
});