<template>
  <div id="resume" class="py-5 text-left">
    <b-container fluid class="col-11 col-lg-10">
      <h1>Resume</h1>
      <b-row class="my-4">
        <b-col class="text-lg-center text-left" lg="8" md="auto">
          <b-row align-h="around">
            <b-col class="text-left" cols="4">
              <h3>Work Experience</h3>
              <ul>
                <li>Software Engineer at Digital Chef (Feb 2020 - present)</li>
                <li>Programmer at DiDutch (Aug 2019 - present)</li>
              </ul>
            </b-col>
            <b-col class="text-left" cols="4">
              <h3>Skills</h3>
              <ul>
                <li>Vue.js, Firebase, Virtual Reality</li>
                <li>Unity3d, Unreal Engine, PlayCanvas</li>
                <li>C#, JavaScript, HTML, CSS</li>
              </ul>
            </b-col>
          </b-row>
          <b-row align-h="around">
            <b-col class="text-left" cols="4">
              <h3 class="mt-5">Education</h3>
              <ul>
                <li>
                  Bachelor Game Design <i>(in progress)</i> - Hanze University
                  Groningen
                </li>
                <li>
                  Propaedeutic Game Design <i>(2018)</i> - Hanze University
                  Groningen
                </li>
              </ul>
            </b-col>
            <b-col class="text-left" cols="4">
              <h3 class="mt-5">Personal Info</h3>
              <ul class="list-unstyled ml-3">
                <li><b>Area:</b> Emmen, the Netherlands</li>
                <li><b>Languages:</b> Dutch, English</li>
                <li>
                  <b>Phone: </b>
                  <span ref="phonenumber"></span>
                  <b-button
                    v-if="!isShowingPhonenumber"
                    @click="showPhoneNumber"
                  >
                    Show
                  </b-button>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>

        <b-col class="text-center mx-4 mt-4 mt-lg-0" md="11" lg="3">
          <!-- <LinkedInBadge /> -->
        </b-col>
      </b-row>

      <!-- PDF download -->
      <b-row align-h="center">
        <a href="/CV_IvoKetelaar.pdf" target="_blank" class="my-4">
          <h1>Download as PDF</h1>
        </a>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import LinkedInBadge from "@/components/LinkedInBadge.vue";
//
export default {
  name: "Resume",
  data() {
    return {
      isShowingPhonenumber: false
    };
  },
  components: {
    // LinkedInBadge
  },
  methods: {
    showPhoneNumber: function() {
      this.isShowingPhonenumber = true;
      this.$refs.phonenumber.textContent = "(+31) 6 53235817";
    }
  }
};
</script>

<style scoped></style>
