<template>
  <div id="intro" class="py-5">
    <b-container fluid class="col-11 col-lg-10">
      <b-row align-h="center">
        <b-col id="person" cols="12" lg="3" class="mt-3">
          <b-img fluid class="portrait" src="/img/avatar.png"></b-img>
          <h1 class="mt-4 text-dark">Ivo Ketelaar</h1>
          <h2 class="text-dark">Programmer</h2>
          <b-row class="social-links px-5 mt-4" align-h="around">
            <a href="mailto:pixel@ivolutio.com" target="_blank">
              <font-awesome-icon :icon="['fas', 'envelope']" />
            </a>
            <a href="https://www.linkedin.com/in/ivo-ketelaar/" target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </a>
            <a href="https://twitter.com/ivolutio" target="_blank">
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
            <a href="https://github.com/Ivolutio" target="_blank">
              <font-awesome-icon :icon="['fab', 'github']" />
            </a>
          </b-row>
        </b-col>

        <b-col
          id="bio"
          class="text-left mt-4 mt-lg-0"
          cols="12"
          lg="7"
          offset-lg="1"
        >
          <h1>About Me</h1>
          <p>
            Experienced Software Engineer with a demonstrated history of working
            in the digital food & gaming industry. Skilled in Vue.js, C#,
            Front-end Development, Unity3d and Firebase. Strong engineering
            professional in his fourth year of Communication and Multimedia
            Design - Game Design (Bachelor) at the Hanzehogeschool Groningen.
          </p>
          <p>
            In my free time I enjoy experimenting with new technologies and
            trying out new tools. My personal projects range from games to
            microcontrollers and anything else new. I am currently working a lot
            with Virtual Reality, with a focus on researching how it can be used
            for social applications.
            <br />
          </p>
          <b-row class="mt-4">
            <b-col>
              <h2>My Interests</h2>
              <ul>
                <li>Game Development</li>
                <li>Web Apps</li>
                <li>Virtual Reality</li>
                <li>Hexagons</li>
              </ul>
            </b-col>
            <b-col>
              <h2>My Inspirations</h2>
              <ul>
                <li>The Legend of Zelda: Breath of the Wild</li>
                <li>Super Mario Odyssey</li>
                <li>Stardew Valley</li>
                <li>Hollow Knight</li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Intro"
};
</script>

<style scoped>
#intro {
  /* min-height: 100vh; */
}

#bio {
  font-size: 1.2em;
}

.social-links a {
  font-size: 3.2em;
  color: #343a40;
  transition: all 0.15s linear;
}
.social-links a:hover {
  color: #75797c;
  transform: scale(1.1);
}

.portrait {
  border-radius: 1000em;
  width: 70%;
  /* max-height: 18em; */
}
</style>
