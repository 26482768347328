<template>
  <div id="showcase" class="py-5">
    <b-container fluid class="col-11 col-lg-10 text-left">
      <h1>Showcase</h1>
    </b-container>
    <b-row align-h="center" class="mt-4 px-4 px-lg-0">
      <ProjectCard
        v-for="(project, index) in projects"
        :key="index"
        :title="project.title"
        :img-src="project.showcaseImg"
        :description="project.shortDescription"
        :project-id="project.id"
      />
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectCard from "@/components/Home/ProjectCard";

export default {
  name: "Showcase",
  components: {
    ProjectCard
  },
  computed: {
    ...mapGetters(["projects"])
  }
};
</script>

<style scoped>
#showcase {
  background-color: rgb(247, 247, 247);
}
</style>
