<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark" sticky>
      <b-navbar-brand to="/" class="ml-2">Ivo Ketelaar</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="text-left ml-3 ml-lg-0">
        <b-navbar-nav>
          <b-nav-item to="/" @click="backToTop">Intro</b-nav-item>
          <b-nav-item href="/#showcase">Showcase</b-nav-item>
          <b-nav-item href="/#resume">Resume</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown text="Projects" class="mr-3" right>
            <b-dropdown-item
              v-for="(project, index) in projects"
              :key="index"
              :to="'/projects/' + project.id"
              >{{ project.title }}</b-dropdown-item
            >
            <!-- <b-dropdown-item to="/projects/1">Project 1</b-dropdown-item>
            <b-dropdown-item to="/projects/2">Project 2</b-dropdown-item> -->
          </b-nav-item-dropdown>

          <!-- Social icons -->
          <b-navbar-nav class="socials row flex-row mx-1">
            <b-nav-item
              href="mailto:pixel@ivolutio.com"
              target="_blank"
              class="mx-2 mx-lg-0"
            >
              <font-awesome-icon :icon="['fas', 'envelope']" />
            </b-nav-item>
            <b-nav-item
              class="mx-2 mx-lg-0"
              href="https://www.linkedin.com/in/ivo-ketelaar/"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </b-nav-item>
            <b-nav-item
              href="https://twitter.com/ivolutio"
              target="_blank"
              class="mx-2 mx-lg-0"
            >
              <font-awesome-icon :icon="['fab', 'twitter']" />
            </b-nav-item>
            <b-nav-item
              href="https://github.com/Ivolutio"
              target="_blank"
              class="mx-2 mx-lg-0"
            >
              <font-awesome-icon :icon="['fab', 'github']" />
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "App",
  beforeCreate() {
    axios
      .get("/projects.json", { responseType: "application/json" })
      .then(result => {
        const data = result.data;
        this.$store.commit("clearProjects");
        this.$store.commit("addProjects", data);
      });
  },
  methods: {
    backToTop: function() {
      document.body.scrollTo(0, 0);
    }
  },
  computed: {
    ...mapGetters(["projects"])
  }
};
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #5a6268 !important;
}
</style>
