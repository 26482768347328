<template>
  <b-card
    :title="title"
    :img-src="imgSrc"
    :img-alt="title"
    img-top
    class="col-xs-12 col-sm-10 col-md-5 col-lg-5 col-xl-3 mx-3 my-3 text-left px-0"
    body-class="d-flex flex-column"
  >
    <b-card-text v-html="description"></b-card-text>

    <b-button
      :to="'/projects/' + projectId"
      variant="primary"
      class="mt-auto align-self-start"
    >
      More info
    </b-button>
  </b-card>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    title: String,
    imgSrc: String,
    description: String,
    projectId: String
  }
};
</script>

<style scoped>
.card {
  transition: all 0.2s ease-out;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.more-info-btn {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
