<template>
  <div class="home">
    <Intro />
    <Showcase />
    <Resume />
  </div>
</template>

<script>
import Intro from "@/components/Home/Intro.vue";
import Showcase from "@/components/Home/Showcase.vue";
import Resume from "@/components/Home/Resume.vue";

export default {
  name: "Home",
  components: { Intro, Showcase, Resume },
  mounted() {
    document.body.scrollTo(0, 0);
    document.title = "Ivo Ketelaar's Portfolio";
  }
};
</script>

<style scoped></style>
